import * as React from 'react';
import {useToken} from '../../common/hooks';
import {
  activate,
  activateNewEmail,
  confirmNewsletter,
} from '../../common/services';
import * as Cookies from 'js-cookie';
import {
  ACTION_CONFIRM_NL,
  ACTION_DOI,
  ACTION_EMAIL_CHANGE,
  NOTIFICATION_CONFIRM_NL_ERROR,
  NOTIFICATION_CONFIRM_NL_SUCCESS,
  NOTIFICATION_COOKIE,
  NOTIFICATION_DOI_EMAIL_ERROR,
  NOTIFICATION_DOI_EMAIL_SUCCESS,
  NOTIFICATION_DOI_ERROR,
  NOTIFICATION_DOI_SUCCESS,
  CLIENT_COOKIE,
  ROUTE_INDEX,
  ROUTE_NEWSLETTER,
} from '../../common/constants';
import {useEffect} from 'react';
import {navigate} from 'gatsby';

export const DoiDispatcher: React.FC = () => {
  const {token, action} = useToken();

  const activateAccount = async () => {
    try {
      const activateResponse = await activate(token);
      Cookies.set(NOTIFICATION_COOKIE, NOTIFICATION_DOI_SUCCESS);
      Cookies.set(CLIENT_COOKIE, activateResponse?.data.client);
    } catch (e) {
      Cookies.set(NOTIFICATION_COOKIE, NOTIFICATION_DOI_ERROR);
    }
    return ROUTE_INDEX;
  };

  const confirmNewsletterSubscription = async () => {
    try {
      await confirmNewsletter(token);
      Cookies.set(NOTIFICATION_COOKIE, NOTIFICATION_CONFIRM_NL_SUCCESS);
      return ROUTE_NEWSLETTER;
    } catch (e) {
      Cookies.set(NOTIFICATION_COOKIE, NOTIFICATION_CONFIRM_NL_ERROR);
      return ROUTE_NEWSLETTER;
    }
  };

  const activateChangedEmail = async () => {
    try {
      await activateNewEmail(token);
      Cookies.set(NOTIFICATION_COOKIE, NOTIFICATION_DOI_EMAIL_SUCCESS);
      return ROUTE_INDEX;
    } catch (e) {
      Cookies.set(NOTIFICATION_COOKIE, NOTIFICATION_DOI_EMAIL_ERROR);
      return ROUTE_INDEX;
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (token && action) {
      switch (action) {
        case ACTION_DOI:
          activateAccount().then(route => navigate(route));
          break;
        case ACTION_CONFIRM_NL:
          confirmNewsletterSubscription().then(route => navigate(route));
          break;
        case ACTION_EMAIL_CHANGE:
          activateChangedEmail().then(route => navigate(route));
          break;
        default:
          navigate!(ROUTE_INDEX);
          break;
      }
    } else {
      timeoutId = setTimeout(() => {
        navigate!(ROUTE_INDEX);
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [token, action]);
  return null;
};
