import * as React from 'react';
import {DoiDispatcher} from '../../components/doi/DoiDispatcher';
import EdekaTitle from '../../components/commons/Title';

export const Head = EdekaTitle;
const DispatchDoi: React.FC = () => {
  return <DoiDispatcher />;
};

export default DispatchDoi;
